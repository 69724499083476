.location-container {
    display: flex;
    flex-direction: column;
}

.address-link {
    background-color: var(--primary-dark-blue);
    color: white;
    text-align: center;
    padding: 0.5em;
    font-size: medium;
}

.contact-main-content iframe {
    border: 0;
    height: 40vh;
    width: 100vw;
}

.home-container iframe {
    border: 0;
    height: 35vh;
    width: 100vw;
}

@media only screen and (max-width: 900px) {
    .home-container iframe {
        width: 100vw;
    }
}