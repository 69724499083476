@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
    --primary-dark-blue: rgb(22, 54, 69);
    --primary-black: #1e1e1e;
}

#root {
    background-color: var(--primary-dark-blue);
}

body {
    margin: 0;
    font-family: 'DM Serif Text', serif;
    overflow: hidden;
}