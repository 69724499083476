.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-dark-blue);
    width: 100%;
    position: fixed;
    z-index: 2;
}

.logo-container {
    display: contents;
    height: 62.5em;
    width: 62.5em;
}

.menu-item {
    font-family: "freehouse", serif;
    font-weight: 400;
    font-style: normal;
}

.close-button {
    height: 2.5em;
    width: 2.5em;
}

.close-button-container {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-dark-blue);
    border-bottom: 1px solid white;
}

.menu-burger {
    height: 2em;
    width: 2em;
    margin: 1em;
    overflow: hidden;
}

.menu .menu-item {
    padding: 1.25em 1em;
    height: 100%;
    color: white;
    font-size: medium;
    transition: 0.3s ease-in-out;
    text-decoration: none;
}

.menu-icon {
    height: 2em;
    padding-left: 0.25em;
}

.menu-mobile {
    height: 100vh;
    background-color: var(--primary-dark-blue);
    text-align: center;
}

.menu-item.mobile {
    display: flex;
    width: 100%;
}

.mobile-menu-item-text {
    padding: 0 0.5em;
}

.menu-mobile-container {
    display: flex;
    flex-direction: column;
}

.menu-mobile-container .MuiPaper-root {
    background-color: var(--primary-dark-blue);
}

.menu-mobile .menu-item {
    text-decoration: none;
    padding: 0.75em 0em;
    border-bottom: 1px solid white;
    justify-content: space-between;
    color: white;
    align-items: center;
    background-color: var(--primary-dark-blue);
}

.logo-img {
    height: 62.5em;
    width: 62.5em;
}

.menu-section-mobile {
    display: none;
}

.menu .menu-item:hover {
    background-color: var(--primary-black);
}

@keyframes slideIn {
    0% {
        transform: translateY(-30em);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media only screen and (max-width: 1000px) {
    .container {
        width: 100vw;
    }

    .menu-section-mobile {
        display: flex;
    }

    .menu {
        display: none;
    }
}