.gallery-page-container {
    height: 100vh;
    padding-top: 8vh;
    overflow: scroll;
    background-color: var(--primary-black);
}

.gallery-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 5em;
    height: 130vh;
}

.section-title {
    color: white;
}

@media only screen and (max-width: 450px) {
    .gallery-main {
        justify-content: stretch;
        height: 110vh;
    }
}