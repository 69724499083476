.service-card-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid white;
    padding: 0.5em;
    background-color: var(--primary-dark-blue);
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.service-card-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0.5em 0
}

.service,
.price {
    font-size: 20px;
}

.description {
    font-size: 16px;
}

.service {
    font-weight: bold;
}

@media only screen and (max-width: 900px) {

    .service,
    .price {
        max-width: 60vw;
        font-size: 14px;
    }

    .description {
        font-size: 12px;
    }
}