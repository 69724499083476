.book-button {
    width: 12em;
    border-radius: 2em;
    text-align: center;
    font-size: large;
    font-weight: bold;
    padding: 0.75em 0.5em;
    color: white;
    text-decoration: none;
    margin: 0.5em;
    background-color: var(--primary-dark-blue);
    transition: 0.2s ease-in-out;
    align-self: center;
    display: flex;
    justify-content: center;
}

.book-button-text {
    margin: 0 1em;
    font-family: 'freehouse', serif;
    font-style: 'normal';
    font-weight: 400;
}

.scissors-icon,
.hair-color-icon {
    position: absolute;
    opacity: 0;
    transition: 1s ease-in-out;
    height: 1.5em;
    width: 1.5em;
}

.book-button:hover {
    background-color: var(--primary-black);
    padding: 0.75em 0.8em;
}

.scissors-icon {
    transform: translateX(2em);
}

.hair-color-icon {
    transform: translateX(-2em);
}

.book-button:hover .scissors-icon,
.book-button:active .scissors-icon {
    animation: fadeInSlideRight 0.3s forwards;
}

.book-button:hover .hair-color-icon,
.book-button:active .hair-color-icon {
    animation: fadeInSlideLeft 0.3s forwards;
}

@media only screen and (max-width: 450px) {
    .book-button {
        padding: 0.5em 0.125em;
    }

    .book-button:hover {
        padding: 0.5em 0.25em;
    }
}

@media only screen and (max-width: 330px) {

    .book-button {
        padding: 0.25em 0.25em;
        font-size: small;
    }

    .book-button:hover {
        padding: 0.25em 0.25em;
    }
}