.contact-container {
    z-index: 1;
    padding-top: 8vh;
    backdrop-filter: brightness(0.7);
    -webkit-backdrop-filter: brightness(0.7);
    color: white;
    display: flex;
    flex-direction: column;
}

.contact-container::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    height: 130vh;
    display: block;
    backdrop-filter: brightness(0.8);
    -webkit-backdrop-filter: brightness(0.8);
}

.contact-page-main {
    background: url('assets/images/salon-outside-with-sign.jpg') center no-repeat;
    background-size: 100%;
    height: 100vh;
    overflow: auto;
}

.contact-main-content {
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.contact-and-hours {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {

    .contact-and-hours {
        flex-direction: column;
        align-items: center;
    }

    .contact-page-main {
        background-size: 190vh;
    }
}