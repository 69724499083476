.service-section-container {
    display: flex;
    flex-direction: column;
    margin: 1em;
    align-items: center;
}

.service-section-heading {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0.5em;
    margin: 0.5em
}

.service-section-title {
    font-size: xx-large;
    margin: 0 0.25em;
}

.service-section-icon {
    height: 1.5em;
    width: 1.5em;
}

.service-section-content {
    display: flex;
    flex-direction: column;
    width: 100%
}