.home-icon-landing {
    height: 2em;
    width: 2em;
    margin: 0 1.5em;
}

.contact-icon-link {
    background-color: var(--primary-black);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin: 0 0.25em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-icon-link:hover {
    background-color: var(--primary-dark-blue);
}

.footer-container {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
}

.footer-container.fade {
    opacity: 0;
    animation: fadeIn 1s 2.5s forwards;
}

.footer-sub-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.5em;
}

@media only screen and (max-width: 900px) {

    .footer-container {
        padding-bottom: 0;
    }

    .home-icon-landing {
        height: 1.75em;
        width: 1.75em;
    }

    .contact-icon-link {
        width: 2.5rem;
        height: 2.5rem;
    }
}