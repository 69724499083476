.services-page-main {
    background: url('assets/images/barbie.jpg') left no-repeat, url('assets/images/shelves.jpg') right no-repeat;
    background-size: 50vw;
    overflow: auto;
    height: 100vh;
}

.services-container {
    backdrop-filter: brightness(0.7);
    -webkit-backdrop-filter: brightness(0.7);
    color: white;
    padding-top: 8vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.services-main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 60vw;
    align-self: center;
    margin: 1em;
}


@media only screen and (max-width: 900px) {

    .services-main-content {
        flex-direction: column;
        width: 100vw;
    }

    .services-page-main {
        background-size: 120vw;
    }
}