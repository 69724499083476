.community-background-container {
    height: 100vh;
    background: url('assets/images/defiant-hair-sign-close-up.jpg') center no-repeat;
    background-size: 100vw;
    width: 100vw;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
}

.community-content {
    height: 100%;
    margin: 0 5em;
    background-color: var(--primary-dark-blue);
    padding: 2em;
}

.community-main {
    height: 120vh;
    padding-top: 8vh;
}

.community-link {
    color: white;
}

.community-backdrop {
    color: white;
    flex-direction: column;
    display: flex;
    align-self: center;
    justify-content: center;
    backdrop-filter: brightness(0.8);
    -webkit-backdrop-filter: brightness(0.8)
}

@media only screen and (max-width: 900px) {
    .community-background-container {
        background-size: 300vh;
    }

    .community-main {
        height: 200vh;
    }
}