.hours-container {
    font-size: xx-large;
    text-align: center;
    background-color: var(--primary-dark-blue);
    padding: 0.25em;
    display: flex;
    align-self: center;
    flex-direction: column;
    max-width: 60vw;
    margin: 1em;
}

.hours-container p {
    margin: 0 0.5em;
}

@media only screen and (max-width: 900px) {
    .hours-container {
        font-size: large;
    }
}