.contact-main {
    display: flex;
    flex-direction: column;
    padding: 0.5em 3em;
    align-self: center;
    justify-content: center;
    align-items: center
}

.contact-button {
    background-color: var(--primary-dark-blue);
    border-radius: 2em;
    text-align: center;
    display: flex;
    padding: 0.5em 1em;
    margin: 0.5em 0;
    font-size: large;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    align-content: center;
    width: 13em;
    color: white;
    transition: 0.2s ease-in-out;
}

.contact-icon {
    height: 2em;
    width: 2em;
}

.contact-text {
    padding-left: 0.5em;
}

.contact-text.email {
    font-size: medium;
}

.contact-button:hover {
    background-color: var(--primary-black);
    padding: 0.5em 1.5em;
}