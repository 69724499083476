.expand-description-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border: 1px solid white;
    border-radius: 10px;
    width: 7em;
    transition: 0.2s ease-in-out;
    margin: 1em 0;
    padding: 0.25em;
}

.card-actions {
    background-color: var(--primary-dark-blue);
    width: 60vw;
    display: flex;
    justify-content: center;
    padding: 0 1em
}

.expand-description-button:hover {
    cursor: pointer;
    border: 1px solid var(--primary-black);
    background-color: var(--primary-black);
    color: white;
}

.card {
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 1em;
    padding: 1em;
    flex-direction: column;
    align-items: center;
}

.card-body {
    display: flex;
    flex-direction: column;
    max-height: 20vh;
    white-space: pre-line;
    overflow: hidden;
    background-color: var(--primary-dark-blue);
    padding: 0.5em 1em;
    transition: 0.5s ease-in-out;
}

.card-body.expanded {
    height: 100%;
    transition: 0.5s ease-in-out;
    white-space: pre-line;
    overflow: auto;
    max-height: 5000px;
}

.card-section {
    margin: 0.25em;
}

.card-body,
.card-actions {
    width: 60vw;
}

@media only screen and (max-width: 900px) {

    .card,
    .card:nth-child(2) {
        flex-direction: column;
        align-items: center;
    }
}