.policies-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin: 0 5em;
    padding-bottom: 3em;
}

.policies-main-content {
    background-color: var(--primary-dark-blue);
    padding: 2em;
}

.policy-header {
    text-decoration: underline;
    font-size: 3vh;
}

.email-link {
    color: white;
}

.policy-logo {
    background-color: white;
    border-radius: 50%;
}

.policy-divider {
    border-top: 3px dashed white;
    width: 100%;
}

.policies-parent {
    padding-top: 8vh;
    color: white;
    flex-direction: column;
    display: flex;
    justify-content: center;
    backdrop-filter: brightness(0.4);
    -webkit-backdrop-filter: brightness(0.4);
}

.divider-icon {
    height: 25px;
    width: 25px;
}

.divider-icon.skull {
    height: 38px;
    width: 38px;
}

.policy-divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.policies-main {
    background: url('assets/images/window-with-logo.jpg') center no-repeat;
    overflow-y: auto;
    height: 100vh;
}

.mini-logo-policies {
    width: 3vw;
    border-radius: 30%;
    padding: 0.5em 0.5em 0 0.5em;
}

.vine-policies {
    width: 100%;
    max-height: 12vh;
}

.vine-policies.flipped {
    transform: scaleX(-1);
}

.vine-policies-mobile {
    display: none;
    height: 8em;
}

.vine-policies-mobile.flipped {
    transform: scaleX(-1) scaleY(-1);
}

@media only screen and (max-width: 900px) {
    .policies-container {
        padding-bottom: 30em;
    }

    .mini-logo-policies {
        width: 8vw;
    }
}

@media only screen and (max-width: 450px) {
    .policy-divider-container {
        flex-direction: column;
    }

    .policies-container {
        padding-bottom: 10em;
        margin: 0 2em;
        padding-top: 4vh;
    }
}